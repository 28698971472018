#postReviewContainer {
    margin: 100px auto;
    display: grid;
    grid-auto-flow: row;
    grid-row-gap: 15px;
    width: 419px;
    align-items: center;
    border-radius: 20px;
    border: 1px solid black;
    padding: 20px;
    font-family: "Manrope", sans-serif;
    margin: -3px auto;
}

.rating-input {
    display: flex;
    margin: auto;
}

.postReviewHeading {
    font-size: 18pt;
    font-weight: bold;
    text-align: center;
}

.comment-input {
    background-color: #E8F0FE;
    width: 100%;
    padding: 5px 0px;
}

.filled {
    color: black
}

.empty {
    color: grey
}
