* {
    font-family: "Manrope", sans-serif;
  }

  .spots-container {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    margin: 20px 17px;
    width: auto;
    /* gap: 4px; */
  }

  .spot {
    width: 200px;
    height: 275px;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 4px;
    border-radius: 10px;
    margin-right: 20px;
  }

  .spot h2 {
    font-size: 20px;
  }

  .spot p {
    font-size: 14px;
  }

  .spot img {
    /* width: 225px; */
    width: 100%;
    height: 170px;
    object-fit: cover;
    border-radius: 10px;
  }

  a {
    text-decoration: none;
  }

  a:visited {
    color: black;
  }

  a:hover {
    /* font-weight: bold; */
    color: #00525E
  }

  .location-and-rating {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .avgRating {
    font-size: 14px;
  }

  .newListing {
    font-size: 14px;
  }

  .fa-star {
    font-size: 14px;
    align-self: center;
    margin-right: 2px;
  }

  .reviews {
    align-self: center;
  }

  .price {
    font-weight: bold;
    margin-right: 5px;
  }

  .price-container {
    font-size: 15px;
    display: flex;
  }
