.form-container{
    margin: 100px auto;
    display: grid;
    grid-auto-flow: row;
    width: 350px;
    align-items: center;
    padding: 10px 40px 10px 40px;
    font-family: 'Manrope', sans-serif;
    margin: 20px auto;
}


.logIn {
    font-size: 24pt;
    font-weight: bold;
    text-align: center;
}

.input {
    background-color: #E8F0FE;
    width: 100%;
    padding: 5px 0px;
}

.text-input {
    width: 100%;
    padding: 5px 0px;
    position: relative;
}


.password {
    margin-bottom: 20px;
}

.submit-button-login {
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 10px 18px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #000000;
    background-color: rgb(218, 156, 156);
    color: white;
    opacity: 0.8;
}


.submit-button-login:hover {
    opacity: 0.8;
    background-color: #FF5A5F;
    color: white;
}

.submit-button-inactive {
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 10px 18px;
}


.demo-button {
    color: white;
    padding: 10px 14px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #000000;
    margin-bottom:20px;
    opacity: 0.8;
    font-family: 'Manrope', sans-serif;
    border-radius: 3px;
    background-color: #94b88a;
}


.demo-button:hover {
    opacity: 0.8;
    background-color: #287510;
    color: white;
}

form > p {
    color: red;
    font-size: 14px;
    margin-top: 0px
}
