.reserveButton {
    width: 100%;
    background-color: rgb(223, 107, 107);
    margin-top: 20px;
    padding: 15px 10px;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    border: 1px solid #000000;
    margin-top: 15px;
    font-size: 15px;
}

.starRating {
    margin-right: 6px;
}

.reviews {
    display: flex;
    justify-content: space-between;
}

.price-reviews {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    font-size: 13pt;
}

.reserve-container {
    border: 1px solid black;
    border-radius: 20px;
    padding: 20px;
    width: 300px;
    margin-right: 15px;
}

.belowImages-info {
    margin: 15px;
    width: 610px;
}

.belowImages {
    display: flex;
    justify-content: space-between;
}

.description {
    font-size: 15px;
    margin: 15px;
}

.preview-image {
    max-width: 500px;
    display: flex;
}

.preview-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.other-image {
    width: 44%;
    object-fit: cover;

}

.images-container {
    margin-bottom: 25px;
    display: flex;
    max-width: 1000px;
    margin-left: 30px;
}

.other-images {
    display: flex;
    max-width: 500px;
    flex-flow: row wrap;
}

.other-image {
    margin: 2px 2px 2px 3px;
    flex-basis: calc(50%-20px)
}

.other-image img {
    width: 100%;
    height: auto;
}

.location {
    font-size: 18px;
    font-weight: bold;
    margin: 15px;
    margin-left: 25px;
}

.hostInfo {
    font-size: 17px;
    font-weight: bold;
    margin: 15px;
}

.name  {
    font-size: 24px;
    font-weight: bold;
    margin: 15px;
    margin-left: 25px;
}

#main-img {
    width: 500px;
    height: 500px;
    /* border-top-left-radius: 20px;
    border-bottom-left-radius: 20px; */
}

.reviewsContainer {
    border-top: 1px solid #999999;
    margin: 15px;
    margin-left: 21px;
    padding-top: 13px;
    width: 1000px;
}

.hiddenReviewContainer {
    display: none
}

.mainReviews {
    display: flex;
    margin-left: 3px;
}

.individualReview {
    display: flex;
    flex-direction: column;
    padding: 11px;
}

.noReviews {
    display: flex;
    align-items: center;
}

.createdAt {
    color: #999999;
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: 12pt;
}

.reviewDescription {
    font-size: 14pt;
    word-wrap: break-word;
}

.reviewUser {
    font-size: 13pt;
    font-weight: bold;
}

.first {
    margin-top: 15px;
    margin-bottom: 9px;
}

.dot {
    margin-right: 6px;
    font-weight: bold;
}

.noReviewsContainer {
    margin-left: 10px;
}

.individualReview > button {
    padding: 5px 10px;
    background-color: #afaeae;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #000000;
    width: 15%;
    color: white;
    margin-top: 5px;
}

.individualReview > button:hover {
    opacity: 0.8;
    background-color: #7a7a7a;
    color: white;
}

.postReview {
    display: flex;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 7px;
}

.postReview > button {
    border: none;
    background-color: #afaeae;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #000000;
    color: white;
    width: 15%;
    margin-top: 5px;
}

.postReview > button:hover {
    opacity: 0.8;
    background-color: #7a7a7a;
    color: white;
}

#page {
    display: flex;
    justify-content: center;
}

/* .spot-container {
    max-width: 1000px;
    justify-content: center;
} */
