#modal {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* animation-name: jump; */
    /* animation-duration: .3s; */
  }

  #modal-background {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  #modal-content {
    /* position: absolute;
    background-color: white; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    background-color:white;
    /* height: 400px; */
    width: 460px;
    border-radius: 15px;
    box-shadow: 0px 3px 15px rgba(0,0,0,0.5);
  }

  /* @keyframes jump {
    0% {top:3000px;}
  } */

  #modal-x  {
    margin-left: 20px;
  }

  #modal-x:hover {
    cursor: pointer;
  }

  #blank {
    margin-right: 35px;
  }

  #top-of-modal {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: lightgray 1px solid;
    height: 60px;
    width: 100%;
  }

  #modal-welcome {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: 80px;
    margin-left: 60px;
    width: 100%;
  }

  #modal-log {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
  }
