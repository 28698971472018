.hidden {
    display: none
}

.profileButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 30px;
    border-radius: 30px;
    border-color: #cdcaca;
    gap: 5px;
}

.profile-dropdown {
    flex-direction: column;
    justify-content: center;
    position: absolute;
    text-align: left;

    background-color: white;
    /* border: 2px solid black; */
    list-style-type: none;

    right: 40px;
    padding-left: 10px;
    margin: 1px 0px;
    /* background-color: white; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 7px 3px #CCCCCC;
}

li {
    padding: 5px 0px;
    list-style: none;
}

#manage-spots {
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
    margin-right: 5px;
    margin-top: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
}

#logout-button {
    height: 33px;
    width: 100px;
    border-radius: 50px;
    border: 2px solid rgb(119, 119, 119);
    color: white;
    background-color: rgb(168, 165, 165);
    align-self: center;
    margin-top: 10px;
}

#logout-button-li {
    justify-content: center;
    text-align: center;
}

.userModals {
    display: flex;
    flex-direction: column;
    padding-right: 7px;
}

.userModals > li:hover {
    cursor: pointer;
}

#logout-button:hover {
    cursor: pointer;
}

.profileButton:hover {
    cursor: pointer;
}
