#noDelete {
    width: 100%;
    background-color: #999999;
    border: 1px solid #999999;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 10px 18px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #000000;
    color: white;
    opacity: 0.8;
}

#noDelete:hover {
    opacity: 0.8;
    background-color: rgb(37, 37, 37);
    color: white;
}

#yesDelete {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid #999999;
    border-radius: 3px;
    margin-bottom: 15px;
    padding: 10px 18px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #000000;
    background-color: rgb(218, 156, 156);
    color: white;
    opacity: 0.8;
}

#yesDelete:hover {
    opacity: 0.8;
    background-color: #FF5A5F;
    color: white;
}

.deleteText {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.deleteHeader {
    text-align: center;
    font-weight: bold;
    font-size: 18pt;
}

.deleteSpotContainer {
    margin: 100px auto;
    display: grid;
    grid-auto-flow: row;
    width: 350px;
    align-items: center;
    padding: 10px 40px 10px 40px;
    margin: 20px auto;
}
